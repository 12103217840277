import queryString from "query-string";
import { HTMLAttributeAnchorTargetEnum, IDataLink, validateAnchorTarget } from "../EditableText/const";

interface HandleIconPropResData {
  icon: string;
  parsedDataLink: IDataLink;
}


export function handleIconProp(iconWithDataLink: string | undefined | string[]): HandleIconPropResData {
  try {
    let processedIconWithDataLink = iconWithDataLink;
    if (processedIconWithDataLink === undefined) {
      return {
        icon: '',
        parsedDataLink: {
          link: '',
          target: HTMLAttributeAnchorTargetEnum._blank,
        },
      };
    }

    if (Array.isArray(processedIconWithDataLink)) {
      const clearSpaceText = processedIconWithDataLink.find(text => text !== ' ');
      if (clearSpaceText) {
        processedIconWithDataLink = clearSpaceText;
      } else {
        throw new Error(`handleIconProp parse error, textProps: ${processedIconWithDataLink}`);
      }
    }

    // 兼容老数据，新数据都会经过 handleUserIconSave 处理，不会触发该 case
    if (!processedIconWithDataLink.includes('=')) {
      return {
        icon: processedIconWithDataLink,
        parsedDataLink: {
          link: '',
          target: HTMLAttributeAnchorTargetEnum._blank,
        },
      };
    }

    const parsed = queryString.parse(processedIconWithDataLink);

    const link = typeof parsed.link === 'string' ? parsed.link : '';

    const icon = typeof parsed.icon === 'string' ? parsed.icon : '';

    const target = validateAnchorTarget(typeof parsed.target === 'string' ? parsed.target : HTMLAttributeAnchorTargetEnum._blank);

    return {
      icon,
      parsedDataLink: {
        link,
        target,
      },
    };
  } catch (error) {
    return {
      icon: '',
      parsedDataLink: {
        link: '',
        target: HTMLAttributeAnchorTargetEnum._blank,
      },
    };
  }
}