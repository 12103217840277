import { request } from "@/api/http";
import { FetchOembedCodeParams, Oembed } from "./const";
import { getJsServerUrl } from "@/const";

export function fetchOembedCode({ type, url }: FetchOembedCodeParams) {
  return request.post<Oembed>(
    "/oembed",
    {
      type,
      url,
    },
    {
      baseURL: getJsServerUrl(),
    }
  );
}
