import { motion } from 'framer-motion';
import React from 'react';

interface props {
  type: 'rise' | 'left' | 'right';
  children: React.ReactNode;
}

// 输出动效标签
export default function AnimateInView(props: props) {
  const { type } = props;
  const animateType = {
    rise: <AnimateRise {...props} />, // 上升动效
    left: <AnimateLeftIn {...props} />, // 左进动效
    right: <AnimateRightIn {...props} />, // 右进动效
  };
  const animate = animateType[type] || animateType.rise;

  return animate;
}

// 上升动效
function AnimateRise({ children }: props) {
  return (
    <motion.div
      whileInView={{
        opacity: [0, 1],
        y: [50, 0],
        transition: {
          duration: 0.8,
          delay: 0.2,
          ease: 'easeInOut',
        },
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
}

// 左边进入
function AnimateLeftIn({ children }: props) {
  return (
    <motion.div
      whileInView={{
        opacity: [0, 1],
        x: [-50, 0],
        transition: {
          duration: 0.8,
          delay: 0.2,
          ease: 'easeInOut',
        },
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
}

// 右边进入
function AnimateRightIn({ children }: props) {
  return (
    <motion.div
      whileInView={{
        opacity: [0, 1],
        x: [40, 0],
        transition: {
          duration: 0.6,
          delay: 0.2,
          ease: 'easeInOut',
        },
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
}
