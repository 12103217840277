import queryString from 'query-string';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import type { EditableButtonProps } from './const';
import { IDataLink, changeTagTitle, getIsDefaultLink, isEditorRoute } from '../EditableText/const';

export default function EditableButton(props: EditableButtonProps) {
  const { className, children, ...restProps } = props;
  const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    restProps.onClick?.(e);
    if (buttonRef.current) {
      const dataLinkElement = buttonRef.current.querySelector('[data-link]');
      // 找到第一个具有 data-link 属性的元素
      if (dataLinkElement) {
        // 如果找到了，返回该属性的值
        const dataLink = dataLinkElement.getAttribute('data-link');
        if (dataLink === null) {
          return;
        }
        try {
          const dataLinkParse = queryString.parse(dataLink) as unknown as IDataLink;
          const { link, target } = dataLinkParse;
          const isDefaultLink = getIsDefaultLink(link);
          if (link === '' || isDefaultLink) {
            return;
          }
          const editorRoute = isEditorRoute(link)
          if(editorRoute !== undefined) {
            navigate(editorRoute.path)
            changeTagTitle(editorRoute.title)
          } else {
            window.open(link, target)
          }
          
        } catch (_error) {
          // 链接解析错误
          console.error('link parse error', dataLink);
        }
      }
    }
  };

  return (
    <button
      ref={buttonRef}
      className={className}
      {...restProps}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
