import { getJsServerUrl, jsDataUrl } from "@/const";
import Axios from "axios";
import CustomRequest from "./customRequest";

export const dataRequest = Axios.create({
  baseURL: jsDataUrl,
});

export const request = new CustomRequest({
  baseURL: getJsServerUrl(),
});
