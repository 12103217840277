import { useCallback, useEffect, useRef } from 'react';

import type { OembedEmbedProps } from './const';

// OembedEmbed 后续可新增 type, 来处理类似情况
function OembedEmbed(props: OembedEmbedProps) {
  const { embedHtml } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const loadTwitterWidgets = useCallback(() => {
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load(containerRef.current);
    }
  }, []);

  useEffect(() => {
    // 检查是否已经加载了 widgets.js 脚本
    const scriptId = 'twitter-wjs';
    if (!document.getElementById(scriptId)) {
      // embedHtml 中可能存在 script，但是其通过 innerHTML 不能正确的加载，network 也不会发送请求
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      script.charset = 'utf-8';
      document.body.appendChild(script);
    }

    // 每次 embedHtml 变化时，更新容器内容并加载 Twitter 小部件
    if (containerRef.current) {
      containerRef.current.innerHTML = embedHtml;
      loadTwitterWidgets();
    }
  }, [embedHtml]);

  return <div ref={containerRef}></div>;
}

export default OembedEmbed;
