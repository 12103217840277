import { dataRequest } from '@api/http';
import type { eventTrackingParams } from './const';
import { jsDataUrl } from '@/const';

/** 埋点数据上报基础函数 */
export async function baseDataWareHouse(data: eventTrackingParams) {
  try {
    navigator.sendBeacon(jsDataUrl, JSON.stringify(data));
  } catch (error) {
  }

  try {
    await dataRequest.post('/', data);
  } catch (error) {
  }
}
