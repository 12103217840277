import { useRef } from 'react';
import { changeTagTitle, getIsDefaultLink, handleTextProp, isEditorRoute } from './const';
import queryString from 'query-string';
import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';
import { getScrollContainer } from '@helpers/common'
interface props {
  propKey: string;
  children?: string;
  className?: string;
}

export default function EditableText(props: props) {
  const { propKey: _propKey, children, className } = props;
  const $edit = useRef<HTMLDivElement>(null);
  const textData = handleTextProp(children);
  const navigate = useNavigate();

  const dataLink = queryString.stringify(textData);

  // EditableIcon 中的 handleClick 需要同步修改（使用 Navigate 替代 navigate 统一函数遇到了 invalid hook call 问题
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { link, target } = textData;
    const isDefaultLink = getIsDefaultLink(link);
    if (link === '' || isDefaultLink) {
      return;
    }
    // link 相关处理 start
    e.stopPropagation();
    const editorRoute = isEditorRoute(link)
    if (editorRoute !== undefined) {
      navigate(editorRoute.path)
      changeTagTitle(editorRoute.title)
      const scrollContainer = getScrollContainer();
      scrollContainer && (scrollContainer.scrollTop = 0);
    } else {
      window.open(link, target)
    }
    // link 相关处理 end
  }

  return (
    <div
      ref={$edit}
      className={`${styles.editable} ${className}`}
      onClick={handleClick}
      data-link={dataLink}
      dangerouslySetInnerHTML={{ __html: textData.text }}
    >
    </div>
  );
};