import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Axios from 'axios';

import { isInvalid } from '@utils/index';
import { getErrorMessage } from './error';

export function globalRequestInterceptor(config: InternalAxiosRequestConfig) {
  return config;
}

// 注释保留，方便后续改造

export function globalResponseInterceptor(response: AxiosResponse) {
  const { data } = response;
  const config = response.config as CustomConfig;
  if (isInvalid(data)) {
    return Promise.reject({
      code: -1,
      data: null,
      message: getErrorMessage()
    });
  }

  const responseData = {
    ...data,
  };

  const allowedCodes = [0];
  if (config.allowedCodes?.length) {
    allowedCodes.push(...config.allowedCodes);
  }

  if (allowedCodes.includes(responseData.code)) {
    return Promise.resolve(responseData);
  }

  // handlePermissionError(responseData.code);

  // !config.hideCodeErrorMessage && CustomMessage.error(getErrorMessage(responseData.key));
  // responseData.message = getErrorMessage(data.key);
  responseData.message = getErrorMessage();
  return Promise.reject(responseData as ResponseData);
}

// eslint-disable-next-line ts/no-explicit-any
export function globalErrorHandlerInterceptor(error: AxiosError): Promise<any> {
  const { response } = error;
  // const config = error.config as CustomConfig;
  if (isInvalid(response)) {
    // !config.hideHTTPErrorMessage && CustomMessage.error(message || getErrorMessage());
    return Promise.reject({
      code: -1,
      data: null,
      message: getErrorMessage(),
    });
  }

  const { status, data: responseData } = response as AxiosResponse;
  const { code, data } = responseData as ResponseData;
  // !config.hideHTTPErrorMessage && CustomMessage.error(getErrorMessage(key));
  return Promise.reject({
    code: code ?? status ?? -1,
    data,
    message: getErrorMessage(),
  });
}

export interface CustomConfig extends AxiosRequestConfig {
  /** 实例拦截器 */
  interceptors?: {
    request?: (<V = InternalAxiosRequestConfig>(value: V) => V | Promise<V>) | null;
    response?: (<V = AxiosResponse>(value: V) => V | Promise<V>) | null;
  };
  /** 临时放行的业务状态码code */
  allowedCodes?: number[];
  /** 隐藏业务错误消息提示（code !== 0） */
  hideCodeErrorMessage?: boolean;
  /** 隐藏请求错误消息提示（status !== 200） */
  hideHTTPErrorMessage?: boolean;
  /** 原始axios instance */
  originalInstance?: boolean;
}

const defaultConfig: CustomConfig = {
  hideCodeErrorMessage: true,
  hideHTTPErrorMessage: true,
};

class CustomRequest {
  instance: AxiosInstance;
  constructor(config: CustomConfig) {
    const { interceptors, originalInstance } = config;
    this.instance = Axios.create({ ...defaultConfig, ...config });
    if (originalInstance) {
      return;
    }
    this.instance.interceptors.request.use(globalRequestInterceptor);
    interceptors?.request && this.instance.interceptors.request.use(interceptors.request);
    this.instance.interceptors.response.use(globalResponseInterceptor, globalErrorHandlerInterceptor);
    interceptors?.response && this.instance.interceptors.response.use(interceptors.response);
  }

  // eslint-disable-next-line ts/no-explicit-any
  post<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.post<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  get<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, config?: CustomConfig) {
    return this.instance.get<T, R, D>(url, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  patch<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.patch<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  delete<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, config?: CustomConfig) {
    return this.instance.delete<T, R, D>(url, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  put<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.put<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  postForm<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.postForm<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  putForm<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.putForm<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  patchForm<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, data?: D, config?: CustomConfig) {
    return this.instance.patchForm<T, R, D>(url, data, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  head<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, config?: CustomConfig) {
    return this.instance.head<T, R, D>(url, config);
  }

  // eslint-disable-next-line ts/no-explicit-any
  options<T = any, R = ResponseData<T, IsDefined<T>>, D = any>(url: string, config?: CustomConfig) {
    return this.instance.options<T, R, D>(url, config);
  }
}

export default CustomRequest;
