import LazyImage from '@components/LazyImage';
import { useEffect, useRef, useState, useMemo } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { getSafeImageExtra, getURLWithoutExtra, isSafeImageExtra } from './helper';

interface props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  propKey: string;
}
interface ImageExtra {
  scale: number;
  x: number;
  y: number;
};
function EditableImg(props: props) {
  const { propKey: _propKey, ref, onLoad, ...rest } = props;
  const [imgExtra, setImgExtra] = useState<ImageExtra>({ x: 0, y: 0, scale: -1 });
  const timerRef = useRef<number>(-1);
  const originImgExtraRef = useRef<ImageExtra>({ x: 0, y: 0, scale: -1 });
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const imageUrlWithoutCropper = useMemo(() => getURLWithoutExtra(rest.src), [rest.src]);
  const initRef = useRef<boolean>(false);
  const [loaded, setLoaded] = useState(initRef.current);

  useEffect(() => {
    const extra = { x: 0, y: 0, scale: -1 };
    if (rest.src) {
      try {
        const { searchParams } = new URL(rest.src);
        extra.x = Number(searchParams.get('x'));
        extra.y = Number(searchParams.get('y'));
        extra.scale = Number(searchParams.get('scale'));
        setImgExtra(extra);
        originImgExtraRef.current = extra;
      } catch (err) {
        console.error(err);
      }
    }
  }, [rest.src]);

  const handleResize = () => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {

      const containRect = {
        width: containerRef.current?.clientWidth || 0,
        height: containerRef.current?.clientHeight || 0,
      };
      const imageRect = {
        width: imageRef.current?.clientWidth || 0,
        height: imageRef.current?.clientHeight || 0,
      };
      const safe = isSafeImageExtra(originImgExtraRef.current, containRect, imageRect);
      if (safe && (originImgExtraRef.current.scale !== imgExtra.scale || originImgExtraRef.current.x !== imgExtra.x || originImgExtraRef.current.y !== imgExtra.y)) {
        setImgExtra(originImgExtraRef.current);
      }
      if (!safe && imgExtra.scale > 0) {
        const safeExtra = getSafeImageExtra(originImgExtraRef.current, containRect, imageRect);
        if (safeExtra.scale !== imgExtra.scale || safeExtra.x !== imgExtra.x || safeExtra.y !== imgExtra.y) {
          setImgExtra(safeExtra);
        }
      }
    }, 350);
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleOnload = (e: any) => {
    const target = e.target as HTMLImageElement;
    target.style.backgroundColor = 'transparent';
    if (!initRef.current) {
      handleResize();
      window.addEventListener('resize', handleResize);
      initRef.current = true;
      setLoaded(true);
    }
    onLoad?.(e);
  }

  if (imgExtra.scale > 0) {
    return (
      <div className={classNames(rest.className, styles.wrapper)} ref={containerRef}>
        <LazyImage
          {...rest}
          ref={imageRef}
          src={imageUrlWithoutCropper}
          style={{
            ...rest.style,
            width: '100%',
            height: 'unset',
            transform: loaded ? `matrix(${imgExtra.scale}, 0, 0, ${imgExtra.scale}, ${imgExtra.x * -1}, ${imgExtra.y * -1})` : 'unset',
            transformOrigin: `${0}px ${0}px`,
            objectPosition: `${0}px ${0}px`,
            borderRadius: 'unset',
            aspectRatio: 'unset',
            margin: 0,
            padding: 0,
          }}
          onLoad={handleOnload}
        />
      </div>
    );
  }
  return <LazyImage {...rest} onLoad={handleOnload} />;
}

export default EditableImg;
