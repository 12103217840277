import { useEffect, useRef } from "react";

import styles from "./index.module.less";
import type { CommonMediaParams } from "../MediaFrame/const";

export type WegicVideoProps = CommonMediaParams;

export const WegicVideo: React.FC<WegicVideoProps> = (props) => {
  const { url, poster, autoplay = false, loop = false } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.muted = mute;
  //   }
  // }, [mute]);

  useEffect(() => {
    if (videoRef.current) {
      if (autoplay) {
        videoRef.current.muted = true;
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [autoplay]);

  return (
    <div className={styles["wegic-video-container"]}>
      <div className={styles["wegic-video-content"]}>
        <video src={url} />
      </div>
      <video
        ref={videoRef}
        className={styles["wegic-video"]}
        controls
        src={url}
        loop={loop}
        autoPlay={autoplay}
        poster={poster}
      />
    </div>
  );
};
