import queryString from 'query-string';
import { FIGMA_REGEX, TypeformPopupSize, type EmbedParams, type EmbedType, type ProductHuntCardParams, type ProductHuntCardSocialProofParams, type ProductHuntPeriod } from './const';
import { convertStringValuesInObject, getHtmlTitle } from '@/utils';

/**
 * @example url为 https://www.producthunt.com/products/wegic，返回 wegic
 */
export function getProductHuntNameFromUrl(url: string) {
  const urlObj = new URL(url);
  const paths = urlObj.pathname.split('/');
  const lastSegment = paths.filter(path => path.length > 0).pop();
  return lastSegment;
}

function validateTheme(theme?: string) {
  const validThemes = ['light', 'dark', 'neutral'];

  if (theme && validThemes.includes(theme)) {
    return theme;
  }

  return 'light';
}

function validatePeriod(period?: ProductHuntPeriod) {
  const validPeriods: ProductHuntPeriod[] = ['daily', 'weekly', 'monthly', 'yearly'];

  if (period && validPeriods.includes(period)) {
    return period;
  }

  return null;
}

export function getProductHuntInfo(params: ProductHuntCardParams) {
  const { url, type, theme: unsafeTheme } = params;

  const theme = validateTheme(unsafeTheme);
  const name = getProductHuntNameFromUrl(url);
  if (!name) {
    throw new Error('Invalid URL');
  }

  let imgUrl = `https://api.producthunt.com/widgets/embed-image/v1/`;
  let linkUrl = `https://www.producthunt.com/posts/${name}?embed=true`;

  if (type === 'socialProof') {
    const { period: unsafePeriod = 'daily' } = params as ProductHuntCardSocialProofParams;
    const period = validatePeriod(unsafePeriod);
    if (period) {
      imgUrl += `top-post-badge.svg?post_id=${name}&theme=${theme}&period=${period}`;
    } else {
      imgUrl += `top-post-badge.svg?post_id=${name}&theme=${theme}`;
    }
    linkUrl += `&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-${name}`;
    return {
      imgUrl,
      linkUrl,
      name,
    };
  }

  if (type === 'reviews') {
    imgUrl += `product_review.svg?product_id=${name}&theme=${theme}`;
    linkUrl = `https://www.producthunt.com/products/${name}/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-${name}`;
    return {
      imgUrl,
      linkUrl,
      name,
    };
  }

  // driveSupport
  imgUrl += `featured.svg?post_id=${name}&theme=${theme}`;
  linkUrl += `&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-${name}`;
  return {
    imgUrl,
    linkUrl,
    name,
  };
}

export function getFigmaInfo(url: string) {
  try {
    const URL_REGEX = FIGMA_REGEX;
    const urlMatch = url.match(URL_REGEX);

    if (urlMatch === null) {
      throw new Error('Invalid URL');
    }

    const { origin, host } = location;
    const embedUrl = `https://www.figma.com/embed?embed_host=${host}&embed_origin=${origin}&url=${url}`;
    return embedUrl;
  } catch (error) {
    console.error('getFigmaInfo error', error);
  }
}

/**
 * 根据输入的内容进行矫正
 */
export function getTypeFromParams(params: EmbedParams[EmbedType] | null, type: EmbedType): EmbedType | null {
  if (!params) {
    return null;
  }

  const typeDeterminers = [
    {
      type: 'twitter',
      condition: () => {
        const { url } = params as EmbedParams['twitter'];
        return url && checkHost(url, ['twitter.com']);
      },
    },
    {
      type: 'facebook',
      condition: () => {
        const { url } = params as EmbedParams['facebook'];
        return url && checkHost(url, ['www.facebook.com', 'fb.watch']);
      },
    },
    {
      type: 'figma',
      condition: () => {
        const { url } = params as EmbedParams['figma'];

        return url && checkHost(url, ['www.figma.com']);
      },
    },
    {
      type: 'googleMap',
      condition: () => (params as EmbedParams['googleMap']).query && (params as EmbedParams['googleMap']).zoom,
    }
  ];

  for (const determiner of typeDeterminers) {
    if (determiner.condition()) {
      return determiner.type as EmbedType;
    }
  }

  return type;
}

function checkHost(url: string, validHosts: string[]): boolean {
  try {
    const parsedUrl = new URL(url);
    return validHosts.includes(parsedUrl.host);
  } catch (e) {
    console.error(`checkHost：${url}`, e);
    return false;
  }
}

export function handleEmbedParams(params: string | undefined | string[]): EmbedParams[EmbedType] | null {
  try {
    let processedParams = params;

    if (!processedParams) {
      return null;
    }

    // 存在换行时， react 会解析成数组
    if (Array.isArray(processedParams)) {
      const clearSpaceText = processedParams.find(text => text !== ' ');
      if (clearSpaceText) {
        processedParams = clearSpaceText;
      } else {
        throw new Error(`handleTextProp parse error, textProps: ${processedParams}`);
      }
    }

    const parsedData = queryString.parse(processedParams) as unknown as EmbedParams[EmbedType];
    // 将解析后的数据转换为适当的类型
    const data = convertStringValuesInObject(parsedData as unknown as Record<string, string>) as unknown as EmbedParams[EmbedType];

    return data;
  } catch (error) {
    console.error(`handleTextProp error: ${error}`);
    return null;
  }
}

export function isIframeString(content: string): boolean {
  const iframeRegex = /<iframe[\s\S]*?<\/iframe>/i;
  return iframeRegex.test(content);
}


export function getFormId(url: string): string | null {
  try {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/').filter(Boolean);
    return pathParts[pathParts.length - 1] || null;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}

export async function getJotformTitle(formId: string) {
  const url = `https://form.jotform.com/${formId}`;
  return getHtmlTitle(url);
}

export function handlePopupSize(size: TypeformPopupSize | undefined) {
  if (!size) {
    return 100;
  }
  switch (size) {
    case 'large':
      return 100;
    case 'medium':
      return 75;
    case 'small':
      return 50;
    default:
      return 100;
  }
}