import type { ImportPackage, PackageLoaderOptions } from './const';

/**
 * 依赖管理
 */
export class PackageManager {
  /** 已加载的依赖 */
  private packageMap: Map<string, ImportPackage>;
  /** 依赖加载器 */
  private loaderMap: Map<string, PackageLoaderOptions>;

  constructor() {
    this.packageMap = new Map();
    this.loaderMap = new Map();
  }

  get size() {
    return {
      loaded: this.packageMap.size,
      pending: this.loaderMap.size,
    };
  }

  /** 注册依赖加载器 */
  set(_name: string, loaderOptions: PackageLoaderOptions) {
    const isFunction = loaderOptions.loader instanceof Function;
    if (!isFunction) {
      console.error('package loader must be a function');
      return;
    }
    const name = _name.toString();
    this.loaderMap.set(name, loaderOptions);
  }

  /** 加载指定名称的依赖 */
  async load(_name: string) {
    const name = _name.toString();
    const loaderOptions = this.loaderMap.get(name);
    const loader = loaderOptions?.loader;
    // 没有注册的依赖
    if (!loader) {
      return null;
    }

    let module = null;
    try {
      // console.log(`fetch package start: ${name}`);
      module = await loader();
      // console.log(`fetch package end: ${name}`);
    } catch (err) {
      console.error(err);
      return null;
    }

    this.packageMap.set(name, module);
    this.loaderMap.delete(name);
    return module;
  }

  /** 获取依赖 */
  async get<T = ImportPackage>(_name: string): Promise<T | null> {
    const name = _name.toString();

    if (this.packageMap.has(name)) {
      // 已加载的依赖
      return this.packageMap.get(name) as T;
    }
    const module = await this.load(name);
    return module as T;
  }

  /** 预加载 */
  async preload(_name?: string, force?: boolean) {
    const name = _name?.toString();
    let packages = [];

    if (force) {
      packages = [...this.loaderMap.keys()];
    } else if (name) {
      packages = [name];
    } else {
      const preloadPackages = [...this.loaderMap.entries()].filter(([_, options]) => options.preload);
      packages = preloadPackages.map(([name]) => name);
    }

    const promiseArr = packages.map(name => this.load(name));
    await Promise.all(promiseArr);
  }
}

const PackageManagerInstance = new PackageManager();
export default PackageManagerInstance;
