import { useCallback, useEffect } from 'react';

import type { EmbedScriptProps } from './const';
import { handleTwitterScript } from './handle';
import { EmbedType } from '../const';

export function useTwitterEmbed(type: EmbedType, params: EmbedScriptProps['params'], containerRef: React.RefObject<HTMLDivElement>) {
  const loadTwitterWidgets = useCallback(() => {
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load(containerRef.current!);
    }
  }, []);

  useEffect(() => {
    if (type !== 'twitter' || !containerRef.current) {
      return;
    }
    if (params?.embedHtml) {
      containerRef.current.innerHTML = params.embedHtml as string;
      handleTwitterScript();
      loadTwitterWidgets();
    }
  }, [type, params?.embedHtml, containerRef.current]);
}
