interface props {
  color: string;
}

interface colorVal {
  from: string;
  via: string;
}

export default function GradientBackground({ color }: props) {
  const colorGroup: Record<string, colorVal> = {
    red: {
      from: '#fecaca', // red-200
      via: '#fca5a5', // red-300
    },
    orange: {
      from: '#fed7aa', // orange-200
      via: '#fdba74', // orange-300
    },
    yellow: {
      from: '#fef08a', // yellow-200
      via: '#fde047', // yellow-300
    },
    green: {
      from: '#bbf7d0', // green-200
      via: '#86efac', // green-300
    },
    sky: {
      from: '#bae6fd', // sky-200
      via: '#7dd3fc', // sky-300
    },
    blue: {
      from: '#bfdbfe', // blue-200
      via: '#93c5fd', // blue-300
    },
    purple: {
      from: '#e9d5ff', // purple-200
      via: '#d8b4fe', // purple-300
    },
    pink: {
      from: '#fbcfe8', // pink-200
      via: '#f9a8d4', // pink-300
    },
  };
  const colorName = colorGroup[color] || colorGroup.sky;

  return <Gradient color={colorName} />;
}

// 渐变样式
function Gradient({ color }: { color: colorVal }) {
  return (
    <div className={`relative flex place-items-center 
         before:absolute before:h-[300px] before:w-full before:-translate-x-1/2 before:rounded-full before:bg-gradient-radial before:from-white before:to-transparent before:blur-2xl before:content-[''] 
         after:absolute after:-z-20 after:h-[160px] after:w-1/2 after:translate-x-1/3 after:bg-gradient-conic after:from-[${color.from}] after:via-[${color.via}] after:blur-2xl after:content-[''] 
         before:lg:h-[360px] z-[-1]`}
    >
    </div>
  );
};
