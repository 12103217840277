interface BaseMediaParams {
  url: string;
}

export interface CommonMediaParams extends BaseMediaParams {
  poster?: string;
  autoplay?: boolean;
  mute?: boolean;
  loop?: boolean;
}

interface SpotifyParams extends BaseMediaParams {
  size?: "compact" | "normal"; // compact 高度为352px, normal 高度为152px
  theme?: "light" | "dark";
}

interface SoundCloudParams extends BaseMediaParams {
  autoplay?: boolean;
}

export type MediaType =
  | "media"
  | "youtube"
  | "vimeo"
  | "spotify"
  | "soundCloud";

export type MediaMimeType = "audio" | "video" | "image";

interface AudioParams extends CommonMediaParams {
  mimeType: "audio";
}

interface VideoParams extends CommonMediaParams {
  mimeType: "video";
}

interface ImageParams extends CommonMediaParams {
  mimeType: "image";
}

/**
 * NOTE: 类型修改后需要同步更新 decodeQueryParams 方法
 */
export interface MediaParams {
  media: AudioParams | VideoParams | ImageParams;
  /**
   * api: https://developers.google.com/youtube/iframe_api_reference?hl=zh-cn
   */
  youtube: CommonMediaParams;
  /**
   * api: https://developer.vimeo.com/api/oembed/videos
   */
  vimeo: CommonMediaParams;
  /**
   * api: https://developer.spotify.com/documentation/embeds/tutorials/using-the-oembed-api
   */
  spotify: SpotifyParams;
  /**
   * api: https://developers.soundcloud.com/docs/oembed
   */
  soundCloud: SoundCloudParams;
}

export interface MediaFrameData {
  title: string;
}

export interface MediaFrameProps<T extends MediaType = "media"> {
  type: T;
  params: MediaParams[T];
  flushId?: string;
  onError?: (error: unknown) => void;
  onLoad?: (data: MediaFrameData) => void;
}

export const IFRAME_BASE_ATTRS: React.IframeHTMLAttributes<HTMLIFrameElement> =
  {
    sandbox:
      "allow-same-origin allow-forms allow-popups allow-modals allow-scripts allow-pointer-lock",
    width: "100%",
    height: "100%",
    allowFullScreen: true,
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
  } as const;
