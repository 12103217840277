import Bowser from "bowser";

declare global {
  interface Window {
    __jsServerUrl__: string;
    __isScreenShotMode__: boolean;
  }
}

export const isOnline = import.meta.env.MODE === "aibuild-zprod";
export const isAws = import.meta.env.MODE === "aibuild-aws";
export const jsDataUrl = "https://data.gees.ai";
export const getJsServerUrl = () => {
  if (window.__jsServerUrl__) {
    return window.__jsServerUrl__;
  }
  return isOnline
    ? "https://wegic.ai/api/onepage"
    : "https://0721-media-widget.aibuild-aws.geesdev.com/api/onepage";
};
export const browser = Bowser.getParser(window.navigator.userAgent);
