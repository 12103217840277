import { Popover, PopupButton, Sidetab, SliderButton, Widget } from '@typeform/embed-react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import IntercomPopoverIcon from '@/assets/icons/IntercomPopover.svg?react';
import type { Oembed } from '@/services/assistant/const';
import { fetchOembedCode } from "@/services/assistant";
import { isURL, removeSearchParams } from '@utils/index';

import type { EmbedFrameProps, EmbedParams, JotformParams } from './const';
import { getFigmaInfo, getFormId, getProductHuntInfo, getTypeFromParams, handleEmbedParams, handlePopupSize, isIframeString } from './handle';
import styles from './index.module.less';
import OembedEmbed from '@components/OembedEmbed';
import { IFRAME_BASE_ATTRS } from "@components/ui/MediaFrame/const";
import EmbedScript from './EmbedScript';
import { handleCalendlyScript, handleCalendlyStyleSheet } from './EmbedScript/handle';
import { FrameScreenShot } from '@components/FrameScreenShot';

function EditableEmbed(props: EmbedFrameProps) {
  const { type: unsafeType, params: unProcessedParams, className, ...restProps } = props;
  const params = handleEmbedParams(unProcessedParams);
  const [embedData, setEmbedData] = useState<Oembed>();
  // 根据 params 纠正 type
  const type = getTypeFromParams(params, unsafeType);
  const render = () => {
    switch (type) {
      case 'twitter': {
        if (!embedData) {
          return null;
        }

        return <OembedEmbed embedHtml={embedData.html} />;
      }
      case 'facebook': {
        const { url } = params as EmbedParams['facebook'];
        if (url === '') {
          return null;
        }
        const embedUrl = `https://www.facebook.com/plugins/post.php?href=${url}`;

        return (
          <iframe
            {...IFRAME_BASE_ATTRS}
            src={embedUrl}
          />
        );
      }
      case 'googleMap': {
        const { query, zoom = 10 } = params as EmbedParams['googleMap'];
        const embedUrl = `https://maps.google.com/maps?q=${query}&z=${zoom}&output=embed`;

        return (
          <iframe
            {...IFRAME_BASE_ATTRS}
            src={embedUrl}
          />
        );
      }
      case 'figma': {
        const { url } = params as EmbedParams['figma'];
        try {
          const embedUrl = getFigmaInfo(url);

          return (
            <iframe
              {...IFRAME_BASE_ATTRS}
              src={embedUrl}
            />
          );
        } catch (error) {
          console.error('figma parse error', error);
          return null;
        }
      }
      case 'productHunt': {
        try {
          const { imgUrl, linkUrl, name } = getProductHuntInfo(params as EmbedParams['productHunt']);

          return (
            <a
              href={linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={imgUrl}
                alt={`${name} | Product Hunt`}
                width="250"
                height="54"
                style={{
                  width: '250px',
                  height: '54px',
                }}
              />
            </a>
          );
        } catch (error) {
          return null;
        }
      }
      case 'customEmbed': {
        const { content } = params as EmbedParams['customEmbed'];
        const type = isURL(content) ? 'url' : 'code';
        const srcProps = type === 'url' ? { src: content } : { srcDoc: content };
        const isIframe = isIframeString(content);
        if (isIframe) {
          return <div style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: content }}></div>;
        }

        return (
          <iframe
            {...IFRAME_BASE_ATTRS}
            {...srcProps}
          />
        );
      }
      case 'jotform': {
        const { url, displayMode = 'standard' } = params as EmbedParams['jotform'];
        const id = getFormId(url);
        if (!id) {
          return null;
        }

        if (displayMode === 'standard') {
          return (
            <iframe
              {...IFRAME_BASE_ATTRS}
              srcDoc={`<script type="text/javascript" src="https://form.jotform.com/jsform/${id}"></script>`}
            />
          );
        }

        if (displayMode === 'popup') {
          return (
            <EmbedScript
              type="jotform"
              params={{
                id,
                displayMode,
              }}
            />
          );
        }
        return null;
      }
      case 'typeform': {
        const { url, displayMode = 'standard', popupSize, autofocus, buttonText = 'Try me!', changeButtonToText = false, position = 'right' } = params as EmbedParams['typeform'];
        const id = getFormId(url);
        if (!id) {
          return null;
        }

        const textStyle: React.CSSProperties = {
          color: '#000000',
          textDecoration: 'underline',
          fontSize: '20px',
          cursor: 'pointer',
        };

        const buttonStyle: React.CSSProperties = {
          display: 'inline-block',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor: '#000000',
          color: '#ffffff',
          fontSize: '20px',
          borderRadius: '25px',
          padding: '0 33px',
          fontWeight: 'bold',
          height: '50px',
          cursor: 'pointer',
          lineHeight: '50px',
          textAlign: 'center',
          margin: 0,
          textDecoration: 'none',
        };

        const style = changeButtonToText ? textStyle : buttonStyle;

        if (displayMode === 'standard') {
          return <Widget id={id} style={{ height: 480 }} autoFocus={autofocus} />;
        }

        if (displayMode === 'popup') {
          const size = handlePopupSize(popupSize);

          return (
            <PopupButton
              id={id}
              style={style}
              size={size}
            >{buttonText}
            </PopupButton>
          );
        }

        if (displayMode === 'slider') {
          return (
            <SliderButton
              id={id}
              position={position}
              style={style}
            >{buttonText}
            </SliderButton>
          );
        }

        if (displayMode === 'sidetab') {
          return <Sidetab id={id} buttonText={buttonText} buttonColor="#000000" />;
        }

        if (displayMode === 'popover') {
          return <Popover id={id} buttonColor="#000000" />;
        }

        return null;
      }
      case 'intercom': {
        return <div className={styles['intercom-comp']}><IntercomPopoverIcon fontSize={24} color="#fff" /></div>;
      }
      case 'calendly': {
        const { url, displayMode = 'standard', buttonText = 'Schedule time with me', changeButtonToText, hideDetailInfo, hideCookieBanner } = params as EmbedParams['calendly'];
        const config = `hide_event_type_details=${hideDetailInfo ? 1 : 0}&hide_gdpr_banner=${hideCookieBanner ? 1 : 0}`;

        const textStyle: React.CSSProperties = {
          color: 'rgb(0, 105, 255)',
          textDecoration: 'underline',
          fontSize: '20px',
          cursor: 'pointer',
        };

        const buttonStyle: React.CSSProperties = {
          display: 'inline-block',
          width: 'auto',
          height: '45px',
          padding: '0 30px',
          borderRadius: '25px',
          boxShadow: 'rgba(0,0,0,.25) 0 2px 5px',
          textAlign: 'center',
          lineHeight: '45px',
          fontWeight: 700,
          fontSize: '14px',
          color: '#fff',
          cursor: 'pointer',
          background: 'rgb(0, 105, 255)',
        };

        const style = changeButtonToText ? textStyle : buttonStyle;

        const embedHtml = {
          standard: <iframe {...IFRAME_BASE_ATTRS} src={`${url}?embed_type=Inline&${config}`} />,

          /* eslint-disable */
          popup: <a
            href="javascript:;"
            onClick={() => {
              window.Calendly.initPopupWidget({ url: `${url}?${config}` });
              return false;
            }}
            style={style}
          >{buttonText}
          </a>,
          /* eslint-enable */
        };

        if (displayMode === 'popup') {
          handleCalendlyStyleSheet();
          handleCalendlyScript();
        }

        return embedHtml[displayMode];
      }
      case 'googleform': {
        const { url } = params as EmbedParams['googleform'];
        return (
          <iframe
            {...IFRAME_BASE_ATTRS}
            src={`${url}?embedded=true`}
          />
        );
      }
      case 'hubspot': {
        const { url } = params as EmbedParams['hubspot'];

        return (
          <iframe
            {...IFRAME_BASE_ATTRS}
            src={url}
          />
        );
      }
      case 'mailchimp': {
        const { content } = params as EmbedParams['mailchimp'];
        return (
          <EmbedScript
            type="mailchimp"
            params={{
              content,
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (type === 'twitter') {
      const { url, theme = 'light' } = params as EmbedParams['twitter'];
      const cleanUrl = removeSearchParams(url);
      const embedUrl = `${cleanUrl}&theme=${theme}`;
      fetchOembedCode({
        type,
        url: embedUrl,
      })
        .then((response) => {
          if (response.data && response.data) {
            setEmbedData(response.data);
          } else {
            return Promise.reject(`fetch oembed error from ${url}`);
          }
        })
        .catch((error) => {
          setEmbedData(undefined);
          console.error("Failed to fetch twitter oEmbed data:", error);
        });
    }
  }, [type, (params as EmbedParams['twitter']).theme, (params as EmbedParams['twitter']).url]);

  if (!type) {
    return null;
  }

  if (window.__isScreenShotMode__) {
    return <FrameScreenShot type={type} name={type} />;
  }

  return (
    <div
      className={classNames(styles['editable-embed'], styles[type], (params as JotformParams).displayMode && styles[(params as JotformParams).displayMode as string], className)}
      {...restProps}
    >
      {render()}
    </div>
  );
}

export default EditableEmbed;
