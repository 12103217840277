import '../PackageManager/init';
import PackageManager from '../PackageManager';
import { dataWareHouse } from '@/helpers/dataWareHose';
import { debounce } from '@/utils/index';
import { scrollContainerId } from '@helpers/common/const';

window.siteEngine = {
  getPackage: PackageManager.get.bind(PackageManager),
  scrollContainerId,
};


const handleClick = debounce(() => {
  dataWareHouse('page_is_interaction', { interaction_class: 'click'  })
}, 300)

const handleScroll = debounce(() => {
  dataWareHouse('page_is_interaction', { interaction_class: 'scroll'  })
}, 300);

const addEventListener = () => {
  window.addEventListener('scroll', handleScroll, true);
  window.addEventListener('click', handleClick, true);
}

const removeEventListener = () => {
  window.removeEventListener('scroll', handleScroll, true);
  window.removeEventListener('click', handleClick, true);
}

addEventListener();
window.addEventListener('beforeunload', removeEventListener);