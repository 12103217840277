import { useEffect } from "react";
import { getIsRemoveBandage } from "./handle";

interface useTypeformPopoverStyleHackerProps {
  enable: boolean;
}

const TYPEFORM_POPOVER_STYLE_ID = 'dynamic-typeform-popover-style';

export default function useTypeformPopoverStyleHacker(props: useTypeformPopoverStyleHackerProps) {
  const { enable } = props;

  const isRemoveBandage = getIsRemoveBandage();

  useEffect(() => {
    if (!enable) {
      return;
    }

    const existedStyleElement = document.getElementById(TYPEFORM_POPOVER_STYLE_ID) as HTMLStyleElement;

    if (existedStyleElement) {
      document.head.removeChild(existedStyleElement);
    }

    const newStyleElement = document.createElement('style');
    newStyleElement.id = TYPEFORM_POPOVER_STYLE_ID;
    newStyleElement.innerHTML = `
        .tf-v1-popover-button {
          right: 20px !important;
          bottom: ${isRemoveBandage ? 26 : 72}px !important;
        }

        .tf-v1-popover-wrapper {
          top: ${isRemoveBandage ? '50%' : 'calc(50% - 50px)'} !important;
        }
      `;
    document.head.appendChild(newStyleElement);
  }, [isRemoveBandage, enable]);
}
