import { useEffect, useState } from 'react';

import type { EmbedScriptProps, HandleJotformScriptDisplayMode } from './const';
import { handleJotformScript } from './handle';
import { EmbedType } from '../const';
import { getJotformTitle } from '../handle';

export function useJotformEmbed(type: EmbedType, params: EmbedScriptProps['params'], containerRef: React.RefObject<HTMLDivElement>) {
  const [formTitle, setFormTitle] = useState<string>('Feedback');

  const { displayMode, id } = params!;

  useEffect(() => {
    if (type !== 'jotform' || !id) {
      return;
    }
    getJotformTitle(id as string)
      .then(title => setFormTitle(title))
      .catch(() => setFormTitle('Feedback'));
  }, [type, id]);

  useEffect(() => {
    if (type !== 'jotform' || !id || !displayMode || !containerRef.current || !formTitle) {
      return;
    }
    handleJotformScript(id as string, displayMode as HandleJotformScriptDisplayMode, formTitle);

    if (displayMode === 'popup' && containerRef.current) {
      const embedHtml = `<a class="btn lightbox-${id}"
                            style="
                            text-transform: uppercase;
                            font-size: 14px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            cursor: pointer;
                            padding: 24px;
                            font-family: inherit;
                            text-shadow: none;
                            user-select: none;
                            transition: all,.1s,ease-in;
                            background-color: #000000;
                            color: #FFFFFF;
                            border-radius: 12px;
                            "
                            onClick={window.jotformClickAction(event);}
                            >
                            ${formTitle}
                            </a>`;
      containerRef.current.innerHTML = embedHtml;
    }
  }, [formTitle, id, displayMode, containerRef.current]);
}
