import classNames from 'classnames';

import styles from './index.module.less';
import { handleIconProp } from './const';
import queryString from 'query-string';
import { changeTagTitle, getIsDefaultLink, isEditorRoute } from '../EditableText/const';
import { useNavigate } from 'react-router-dom';
import { getScrollContainer } from '@helpers/common';
interface EditableIconProps {
  /** DSL key */
  propKey: string;
  /** 透传的样式 */
  className?: string;
  /** 图标唯一标识 */
  icon: string;
  /** 图标库，默认为FontAwesome */
  iconLibrary?: string;
}


function EditableIcon(props: EditableIconProps) {
  const { icon: iconWithDataLink, iconLibrary = 'FontAwesome', className = '', ...rest } = props;

  const { icon, parsedDataLink } = handleIconProp(iconWithDataLink);
  const stringifiedDataLink = queryString.stringify(parsedDataLink);
  const navigate = useNavigate();

  // EditableText 中的 handleClick 需要同步修改（使用 Navigate 替代 navigate 统一函数遇到了 invalid hook call 问题
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { link, target } = parsedDataLink;
    const isDefaultLink = getIsDefaultLink(link);
    if (link === '' || isDefaultLink) {
      return;
    }
    // link 相关处理 start
    e.stopPropagation();
    const editorRoute = isEditorRoute(link)
    if (editorRoute !== undefined) {
      navigate(editorRoute.path)
      changeTagTitle(editorRoute.title)
      const scrollContainer = getScrollContainer();
      scrollContainer && (scrollContainer.scrollTop = 0);
    } else {
      window.open(link, target)
    }
    // link 相关处理 end
  }


  return (
    <div
      className={classNames(styles['icon-wrapper'], {
        [styles['cursor-pointer']]: !!parsedDataLink.link
      }, className)}
      onClick={handleClick}
      data-library={iconLibrary}
      data-link={stringifiedDataLink}
      {...rest}
    >
      <i className={classNames(styles.icon, icon)} />
    </div>

  );
}

export default EditableIcon;
