import { Slider } from "@arco-design/web-react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import PauseIcon from "@assets/icons/Pause.svg?react";
import PlayIcon from "@assets/icons/Play.svg?react";

import styles from "./index.module.less";
import type { CommonMediaParams } from "../MediaFrame/const";
import { RoundIconButton } from "../RoundIconButton";

export type WegicAudioProps = Omit<CommonMediaParams, "poster">;

export const WegicAudio: React.FC<WegicAudioProps> = (props) => {
  const { url: src, mute = false, autoplay = false, loop = false } = props;

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);

  const handleSliderChange = (val: number | number[]) => {
    if (audioRef.current) {
      audioRef.current.currentTime = val as number;
      setIsSliding(true);
      const isFinish = (val as number) >= duration;
      setCurrentTime(isFinish ? duration : (val as number));
    }
  };

  const handleAfterChange = () => {
    setIsSliding(false);
  };

  const handleTogglePlay = async (play: boolean) => {
    if (audioRef.current) {
      if (play) {
        try {
          await audioRef.current.play();
        } catch (err) {}
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(Math.min(audioRef.current!.currentTime, duration));
  };

  const handlePlayEnd = () => {
    setIsPlaying(false);
  };

  const handleDurationChange = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const getFormatTime = (timestamp: number) => {
    timestamp = Math.round(timestamp);
    const second = timestamp % 60;
    const minute = ~~(timestamp / 60);
    return `${minute}:${second > 9 ? second : `0${second}`}`;
  };

  useEffect(() => {
    if (canPlay) {
      handleTogglePlay(autoplay);
    }
    if (!autoplay) {
      setIsPlaying(false);
    }
  }, [canPlay, autoplay]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener(
        "durationchange",
        handleDurationChange,
        { once: true }
      );
    }
  }, [src]);

  return (
    <div className={styles["wegic-audio"]}>
      <audio
        ref={audioRef}
        src={src}
        loop={loop}
        muted={mute}
        autoPlay={autoplay}
        onEnded={handlePlayEnd}
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => setIsPlaying(true)}
        onCanPlay={() => setCanPlay(true)}
        onPause={() => setIsPlaying(false)}
      />
      <div className={styles["wegic-audio-controls"]}>
        <RoundIconButton
          size="small"
          disabled={!canPlay}
          onClick={() => handleTogglePlay(!isPlaying)}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </RoundIconButton>
        <div className={styles["wegic-audio-progress"]}>
          <Slider
            className={classNames({
              [styles["wegic-audio-slider"]]: true,
              [styles["wegic-audio-slider_sliding"]]: isSliding,
            })}
            value={currentTime}
            min={0}
            step={1}
            max={duration}
            disabled={!canPlay}
            tooltipVisible={false}
            onChange={handleSliderChange}
            onAfterChange={handleAfterChange}
          />
        </div>
      </div>
      <div className={styles["wegic-audio-time"]}>
        <span>{getFormatTime(currentTime)}</span>
        <span className={styles["wegic-audio-time_separator"]}>/</span>
        <span>{getFormatTime(duration)}</span>
      </div>
    </div>
  );
};
