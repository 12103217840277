const xssRegex = /\s+on([\s\S]*)\=[\"\']([\s\S]*)([\"\']{1})\s+$/g;

/**
 * transform a string to a dom element
 * @param domString
 * @returns {HTMLElement | null}
 */
export function parseStringToDom<T extends HTMLElement>(
  domString: string
): T | null {
  const safeString = domString.replace(/\\/, "").replace(xssRegex, "");
  const template = document.createElement("template");
  template.innerHTML = safeString;
  const dom = template.content.firstChild;
  if (!dom) {
    return null;
  }
  return dom as T;
}
