export function awaitImgLoaded(src: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(src);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = src;
  });
}
