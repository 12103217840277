interface BaseEmbedParams {
  url: string;
}

export interface TwitterParams extends BaseEmbedParams {
  theme?: 'light' | 'dark';
}

interface GoogleMapParams {
  query: string; // 经过网址转义的地点名称、地址、Plus 代码或地点 ID
  zoom?: number; // 值的范围为 0（全世界）到 21（个别建筑物）,默认为 10
}

export interface ProductHuntBaseParams extends BaseEmbedParams {
  theme?: 'light' | 'dark' | 'neutral';
}

interface ProductHuntCardType<T> extends ProductHuntBaseParams {
  type: T;
}

type ProductHuntCardReviewsParams = ProductHuntCardType<'reviews'>;

type ProductHuntCardDriveSupportParams = ProductHuntCardType<'driveSupport'>;

export interface ProductHuntCardSocialProofParams extends ProductHuntCardType<'socialProof'> {
  period?: ProductHuntPeriod;
}

export type ProductHuntPeriod = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type ProductHuntCardParams = ProductHuntCardReviewsParams | ProductHuntCardDriveSupportParams | ProductHuntCardSocialProofParams;

interface CustomParams {
  content: string;
}

interface CustomParams {
  content: string;
}

export interface JotformParams extends BaseEmbedParams {
  displayMode?: 'standard' | 'popover' | 'popup';
}

interface TypeformParams extends BaseEmbedParams {
  displayMode?: 'standard' | 'popover' | 'popup' | 'slider' | 'sidetab';
  autofocus?: boolean;
  popupSize?: TypeformPopupSize;
  buttonText?: string;
  changeButtonToText?: boolean;
  position?: 'left' | 'right';
}

export type TypeformPopupSize = 'large' | 'medium' | 'small';

interface CalendlyParams extends BaseEmbedParams {
  displayMode?: 'standard' | 'popup';
  buttonText?: string;
  hideDetailInfo?: boolean;
  hideCookieBanner?: boolean;
  changeButtonToText?: boolean;
}

interface MailchimpParams {
  content: string;
}

interface IntercomParams {
  id: string;
}

export type EmbedType = 'twitter' | 'facebook' | 'googleMap' | 'figma' | 'productHunt' | 'customEmbed' | 'jotform' | 'typeform' | 'googleform' | 'intercom' | 'calendly' | 'mailchimp' | 'hubspot';

export interface EmbedParams {
  /**
   * api: https://developer.x.com/en/docs/twitter-for-websites/embedded-tweets/overview
   */
  twitter: TwitterParams;
  /**
   * api: https://developers.facebook.com/docs/plugins/oembed
   */
  facebook: BaseEmbedParams;
  /**
   * 当前版本：https://maps.google.com/maps?q=北京&z=10&output=embed
   * 后续如果做一些升级配置，参考 api: https://developers.google.com/maps/documentation/embed/embedding-map?hl=zh-cn
   */
  googleMap: GoogleMapParams;
  /**
   * api: https://www.figma.com/developers/embed
   */
  figma: BaseEmbedParams;
  /**
   * 参考文档：https://fyze31atzb.feishu.cn/wiki/Ux3kwvFrKi6vuTkhZG2cH6ZpnTc?fromScene=spaceOverview#part-HaJkdsdXnoaUfXx8rCBcfJZGn3g
   * @example https://www.producthunt.com/products/wegic
   */
  productHunt: ProductHuntCardParams;
  /**
   * api: https://developer.mozilla.org/zh-CN/docs/Web/HTML/Element/iframe
   */
  customEmbed: CustomParams;

  jotform: JotformParams;

  typeform: TypeformParams;

  intercom: IntercomParams;

  calendly: CalendlyParams;

  googleform: BaseEmbedParams;

  hubspot: BaseEmbedParams;

  mailchimp: MailchimpParams;
}

export interface EmbedFrameData {
  title: string;
}

export interface EmbedFrameProps {
  type: EmbedType;
  params: string;
  className?: string;
  propKey: string;
}

// REGEX
export const FIGMA_REGEX = /https:\/\/[\w\.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
export const NONEMPTY_REGEX = /.+/;