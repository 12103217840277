import queryString from "query-string";
import React, { forwardRef, memo, useEffect, useState } from "react";

import { fetchOembedCode } from "@/services/assistant";
import type { OembedType } from "@/services/assistant/const";
import { parseStringToDom } from "@utils/domParser";

import {
  IFRAME_BASE_ATTRS,
  type MediaFrameProps,
  type MediaParams,
  type MediaType,
} from "./const";
import { addSearchParams, getValidMediaFrameUrl } from "./handle";
import styles from "./index.module.less";
import { WegicAudio } from "../WegicAudio";
import { WegicVideo } from "../WegicVideo";

function MediaFrameComponent<T extends MediaType>(
  props: MediaFrameProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { type, params, flushId = "", onError, onLoad } = props;

  const [url, setUrl] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const handleGetValidURL = async () => {
    try {
      setHasError(false);
      const url = getValidMediaFrameUrl(type, params.url, params);
      const EMBED_TYPES: MediaType[] = ["vimeo", "spotify", "soundCloud"];
      if (EMBED_TYPES.includes(type)) {
        const res = await fetchOembedCode({
          type: type as OembedType,
          url,
        });
        const iframe = parseStringToDom<HTMLIFrameElement>(res.data.html);
        if (iframe) {
          setUrl(iframe.src);
          onLoad?.({
            title: res.data.title || res.data.url,
          });
          return;
        }
        throw new Error(`Invalid embed data`);
      } else {
        setUrl(url);
      }
    } catch (err) {
      console.error("Failed to fetch embed data:", err);
      setUrl("");
      setHasError(true);
      onError?.(err);
    }
  };

  const render = () => {
    if (hasError) {
      return null;
    }
    let src = url;
    if (!src) {
      return null;
    }
    src = addSearchParams(src, { flushId });
    if (type === "media") {
      const { mimeType, poster, autoplay, mute, loop } =
        params as MediaParams["media"];
      if (mimeType === "audio") {
        return (
          <WegicAudio url={src} autoplay={autoplay} mute={mute} loop={loop} />
        );
      }
      if (mimeType === "video") {
        return (
          <WegicVideo
            url={src}
            poster={poster}
            autoplay={autoplay}
            mute={mute}
            loop={loop}
          />
        );
      }
      // img
      return null;
    }
    const attrs: React.IframeHTMLAttributes<HTMLIFrameElement> = {
      ...IFRAME_BASE_ATTRS,
    };
    if (type === "spotify") {
      const { theme, size } = params as MediaParams["spotify"];
      src = `${url}&${queryString.stringify({
        theme: theme === "light" ? 1 : 0,
      })}`;
      attrs.height = size === "compact" ? 152 : 352;
    } else if (type === "soundCloud") {
      src = `${url}&${queryString.stringify({
        auto_play: (params as MediaParams["soundCloud"]).autoplay,
      })}`;
    }
    return <iframe src={src} {...attrs} />;
  };

  useEffect(() => {
    handleGetValidURL();
  }, [type, params]);

  return (
    <div ref={ref} className={styles["media-frame"]}>
      {render()}
    </div>
  );
}

interface IForwardRefMediaFrame
  extends React.ForwardRefExoticComponent<
    React.PropsWithoutRef<MediaFrameProps> & React.RefAttributes<HTMLDivElement>
  > {
  <T extends MediaType>(
    props: MediaFrameProps<T> & {
      ref?: React.Ref<HTMLDivElement>;
    } & Omit<React.HTMLAttributes<HTMLDivElement>, "onError" | "onLoad">
  ): React.ReactElement;
}

export const MediaFrame = memo(
  forwardRef(MediaFrameComponent)
) as IForwardRefMediaFrame;
