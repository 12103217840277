import {
  getThirdPartyBrandLogo,
  ThirdPartyBrand,
} from "@/helpers/third-party/getThirdPartyBrandLogo";
import styles from "./index.module.less";

export interface FrameScreenShotProps {
  type: ThirdPartyBrand;
  name: string;
}

export const FrameScreenShot: React.FC<FrameScreenShotProps> = (props) => {
  const Logo = getThirdPartyBrandLogo(props.type);
  return (
    <div className={styles["frame-screenshot"]}>
      <div className={styles["frame-screenshot-content"]}>
        <div className={styles["frame-brand-logo"]}>
          {Logo && <Logo fontSize={30} />}
        </div>
        <div className={styles["frame-brand-name"]}>{props.name}</div>
      </div>
    </div>
  );
};
