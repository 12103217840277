import React from "react";
import { createRoot } from "react-dom/client";
import AnimateInView from "@modules/BaseComponent/AnimateInView";
import GradientBackground from "@modules/BaseComponent/GradientBackground";
import EditableImg from "@modules/BaseComponent/EditableImg";
import EditableText from "@modules/BaseComponent/EditableText";
import EditableIcon from "@modules/BaseComponent/EditableIcon";
import EditableButton from "@modules/BaseComponent/EditableButton";
import ErrorBoundary from "@components/ErrorBoundary";
import RouterWrap from "@modules/BaseComponent/RouterWrap";
import Marquee from "react-fast-marquee";
import Carousel from "nuka-carousel";
import * as ReactRouterDom from "react-router-dom";
import * as framerMotion from "framer-motion";
import PackageManager from ".";
import EditableEmbed from "@modules/BaseComponent/EditableEmbed";
import EditableMedia from "@modules/BaseComponent/EditableMedia";
import EmbedSidetab from "@modules/BaseComponent/EditableEmbed/EmbedSidetab";
import EmbedPopover from "@modules/BaseComponent/EditableEmbed/EmbedPopover";

// 注册依赖
PackageManager.set("React", { loader: async () => React, preload: true });
PackageManager.set("createRoot", {
  loader: async () => createRoot,
  preload: true,
});

PackageManager.set("@Basic/AnimateInView", {
  loader: async () => AnimateInView,
  preload: true,
});
PackageManager.set("@Basic/GradientBackground", {
  loader: async () => GradientBackground,
  preload: true,
});
PackageManager.set("@Basic/EditableImg", {
  loader: async () => EditableImg,
  preload: true,
});
PackageManager.set("@Basic/EditableText", {
  loader: async () => EditableText,
  preload: true,
});

PackageManager.set("@Basic/EditableIcon", {
  loader: async () => EditableIcon,
  preload: true,
});

PackageManager.set("@Basic/EditableButton", {
  loader: async () => EditableButton,
  preload: true,
});

PackageManager.set("@Basic/EditableEmbed", {
  loader: async () => EditableEmbed,
  preload: true,
});

PackageManager.set("@Basic/EmbedPopover", {
  loader: async () => EmbedPopover,
  preload: true,
});

PackageManager.set("@Basic/EmbedSidetab", {
  loader: async () => EmbedSidetab,
  preload: true,
});

PackageManager.set("@Basic/EditableMedia", {
  loader: async () => EditableMedia,
  preload: true,
});

PackageManager.set("ErrorBoundary", {
  loader: async () => ErrorBoundary,
  preload: true,
});
PackageManager.set("Marquee", {
  loader: async () => Marquee,
  preload: true,
});
PackageManager.set("Carousel", {
  loader: async () => Carousel,
  preload: true,
});
PackageManager.set("ReactRouterDom", {
  loader: async () => ReactRouterDom,
  preload: true,
});
PackageManager.set("framerMotion", {
  loader: async () => framerMotion,
  preload: true,
});
PackageManager.set("motion", {
  loader: async () => framerMotion.motion,
  preload: true,
});
PackageManager.set("AnimatePresence", {
  loader: async () => framerMotion.AnimatePresence,
  preload: true,
});
PackageManager.set("RouterWrap", {
  loader: async () => RouterWrap,
  preload: true,
});

PackageManager.preload();
