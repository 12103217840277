import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  fallbackChildren?: React.ReactNode;
  fallbackHandle?: (error: unknown, info: unknown) => void;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, info: unknown) {
    const { fallbackHandle } = this.props;
    console.error(error, info);
    fallbackHandle && fallbackHandle(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallbackChildren } = this.props;

    if (hasError) {
      return fallbackChildren;
    }

    return children;
  }
}
