import axios from 'axios';
import { isEmpty, isNull, isUndefined } from 'lodash-es';
import { nanoid } from 'nanoid';

/**
 * 当前毫秒时间戳 （utc时间）
 */
export function formatUTCTimestamp() {
  const currentTimestamp = Date.now();
  const timezoneOffsetMinutes = new Date().getTimezoneOffset();
  const utcOffsetMilliseconds = timezoneOffsetMinutes * 60 * 1000;
  const utcTimestamp = currentTimestamp + utcOffsetMilliseconds;
  return utcTimestamp;
}

/**
 * 获取唯一id
 */
export function getUniqueId() {
  let uniqueID = localStorage.getItem('uniqueID');
  if (!uniqueID) {
    uniqueID = nanoid(24);
    localStorage.setItem('uniqueID', uniqueID);
  }
  return uniqueID;
}

/**
 * 获取会话的唯一id
 */
export function getSessionId() {
  let sessionId = sessionStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = nanoid(10);
    sessionStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
}

/**
 * @example http://wegic.ai/app?react=1，处理后返回 http://wegic.ai/app
 */
export function removeSearchParams(url: string) {
  const { origin, pathname } = new URL(url);
  return origin + pathname;
}

type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number): DebouncedFunction<T> {
    let timeoutId: number | undefined;

    return function(this: ThisParameterType<T>, ...args: Parameters<T>) {
        const context = this;

        if (timeoutId !== undefined) {
          clearTimeout(timeoutId);
        }

        timeoutId = window.setTimeout(() => {
          func.apply(context, args);
        }, wait);
    };
}

export const isInvalid = (value: unknown): boolean => isUndefined(value) || isNull(value) || isEmpty(value);

export function isURL(str: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

export async function getHtmlTitle(url: string): Promise<string> {
  try {
    const response = await axios.get(url, { responseType: 'text' });
    const html = response.data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const titleElement = doc.querySelector('title');

    if (titleElement && titleElement.textContent) {
      return titleElement.textContent.trim();
    }
    throw new Error('Title Not Found');
  } catch (error) {
    console.error('Error obtaining HTML title:', error);
    throw error;
  }
}

export function executeWithRetry(fn: () => boolean, maxAttempts: number, interval: number): Promise<void> {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    const tryExecute = () => {
      if (fn()) {
        resolve();
      } else if (++attempts < maxAttempts) {
        setTimeout(tryExecute, interval);
      } else {
        reject(new Error(`Failed after ${maxAttempts} attempts`));
      }
    };
    tryExecute();
  });
}

export function loadScript(src: string, id?: string) {
  return new Promise((resolve, reject) => {
    if (id && document.getElementById(id)) {
      resolve({});
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    if (id) {
      script.id = id;
    }
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function loadStyleLink(src: string, id?: string) {
  return new Promise((resolve, reject) => {
    if (id && document.getElementById(id)) {
      resolve({});
      return;
    }
    const link = document.createElement('link');
    link.href = src;
    link.rel = 'stylesheet';
    if (id) {
      link.id = id;
    }
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
  });
}

export function convertStringValuesInObject<T extends Record<string, string>>(obj: T): { [K in keyof T]: string | number | boolean } {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      if (value.toLowerCase() === 'true') {
        acc[key as keyof T] = true;
      } else if (value.toLowerCase() === 'false') {
        acc[key as keyof T] = false;
      } else if (!Number.isNaN(Number(value))) {
        acc[key as keyof T] = Number(value);
      } else {
        acc[key as keyof T] = value;
      }
    } else {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as { [K in keyof T]: string | number | boolean });
}
