import classnames from 'classnames';

import styles from './index.module.less';

export interface RoundIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
}
export const RoundIconButton: React.FC<RoundIconButtonProps> = (props) => {
  const { size = 'medium', className = '', disabled = false, children, ...nativeProps } = props;

  const rootClassName = classnames(
    className,
    styles['round-icon-button'],
    {
      [styles[`round-icon-button-${size}`]]: Boolean(size),
    },
  );

  return (
    <button className={rootClassName} disabled={disabled} {...nativeProps}>
      {children}
    </button>
  );
};
