import { executeWithRetry, loadScript, loadStyleLink } from '@utils/index';

import type { HandleJotformScriptDisplayMode } from './const';

export function handleTwitterScript() {
  loadScript('https://platform.twitter.com/widgets.js', 'twitter-wjs');
}

export async function handleJotformScript(id: string, displayMode: HandleJotformScriptDisplayMode, title = 'Feedback') {
  const isPopover = displayMode === 'popover';
  try {
    // 1. 加载 feedback2.js
    await loadScript('https://cdn.wegic.ai/assets/js/feedback2.js', 'Jotform-feedback2');

    // 2. 加载 for-form-embed-handler.js
    await loadScript('https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js', 'Jotform-handler');
    // 3. 执行 JotformFeedback 配置脚本
    await executeWithRetry(() => {
      if (typeof window.JotformFeedback === 'undefined') {
        return false;
      }
      const scriptId = `Jotform-component-${displayMode}-${id}`;
      const existedScript = document.getElementById(scriptId);
      if (existedScript) {
        return true;
      }
      const script = document.createElement('script');
      script.id = scriptId;
      const content = isPopover
        ? `
          var componentID = new JotformFeedback({
            type: '1',
            width: 700,
            height: 500,
            fontColor: "#FFFFFF",
            background: "#000000",
            isCardForm: false,
            formId: "${id}",
            buttonText: "${title}",
            buttonSide: "bottom",
            buttonAlign: "left",
            base: "https://form.jotform.com/",
          }).componentID;
        `
        : `
          var JFL_${id} = new JotformFeedback({
            formId: ${id},
            base: 'https://form.jotform.com/',
            windowTitle: '${title}',
            backgroundColor: '#000000',
            fontColor: '#FFFFFF',
            type: '1',
            height: 500,
            width: 700,
            openOnLoad: false
          });
          window.jotformInstance = JFL_${id};
          window.jotformClickAction = (e) => {
            window.jotformInstance.showWindow();
            e.stopPropagation();
            e.preventDefault();
          }
        `;
      script.textContent = content;
      document.body.appendChild(script);
      return true;
    }, 20, 400); // 最多尝试 20 次，每次间隔400ms

    // 4. 执行 jotformEmbedHandler 调用脚本
    await executeWithRetry(() => {
      if (typeof window.jotformEmbedHandler === 'undefined') {
        return false;
      }
      const handlerScriptId = `Jotform-jotformEmbedHandler-${id}`;
      const existedScript = document.getElementById(handlerScriptId);
      if (existedScript) {
        return true;
      }
      const script = document.createElement('script');
      script.id = handlerScriptId;
      script.textContent = isPopover
        ? `window.jotformEmbedHandler("iframe[id='" + componentID + "_iframe']", "https://form.jotform.com/")`
        : `window.jotformEmbedHandler("iframe[id='${id}']", "https://form.jotform.com/")`;
      document.body.appendChild(script);
      return true;
    }, 20, 400); // 最多尝试20次，每次间隔400ms
  } catch (error) {
    console.error('load Jotform error:', error);
  }
}

export function handleCalendlyScript() {
  return loadScript('https://assets.calendly.com/assets/external/widget.js', 'calendly-js');
}

export function handleCalendlyStyleSheet() {
  loadStyleLink('https://assets.calendly.com/assets/external/widget.css', 'calendly-style');
}

export function handleMailchimpScript() {
  loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js', 'mailchimp-js').then(() => {
    const scriptId = `mailchimp-new`;
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      const content = `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);`;
      script.textContent = content;
      document.body.appendChild(script);
    }
  });
}

export function getIsRemoveBandage() {
  return !document.getElementById('wegic-badge');
}