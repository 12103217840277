import { useLatest } from 'ahooks';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { awaitImgLoaded } from './const';

type HTMLImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

interface LazyImageProps extends HTMLImageProps {
  // 懒加载触发热区，单位px
  rootMargin?: number;
  // 等待图片加载完成显示
  waitForImagesToLoad?: boolean;
}

/**
 * 图片懒加载，用法和img标签一模一样
 */
export default forwardRef<HTMLImageElement | null, LazyImageProps>((props, ref) => {
  const { src, waitForImagesToLoad = false, rootMargin = 100, ...reset } = props;
  const $img = useRef<HTMLImageElement>(null);
  const waitForImagesToLoadRef = useLatest(waitForImagesToLoad);

  useImperativeHandle(ref, () => {
    return $img.current!;
  }, []);

  useEffect(() => {
    const img = $img.current;

    if (!img || !src) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLImageElement;
          if (waitForImagesToLoadRef.current) {
            awaitImgLoaded(src).then(() => {
              target.src = src ?? '';
              observer.disconnect();
            });
          } else {
            target.src = src ?? '';
            observer.disconnect();
          }
        }
      });
    }, {
      rootMargin: `${rootMargin}px`,
    });

    observer.observe(img);

    return () => {
      observer.disconnect();
    };
  }, [src, rootMargin]);

  return (
    <img {...reset} ref={$img} />
  );
});
