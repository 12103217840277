import { nanoid } from 'nanoid';

import { baseDataWareHouse } from '@services/dataWareHose';
import type { eventTrackingParams } from '@services/dataWareHose/const';
import { browser, isOnline } from '@const/index';
import { formatUTCTimestamp, getSessionId, getUniqueId } from '@utils/index';

import { actionToTheme, trackingCountKey } from './const';

/**
 * 埋点计数
 */
export function trackEvent() {
  let trackingCount = Number.parseInt(sessionStorage.getItem(trackingCountKey) ?? '0');
  trackingCount++;
  sessionStorage.setItem(trackingCountKey, trackingCount.toString());
}

export function getOption(action: keyof typeof actionToTheme, customProperties?: Record<string, unknown>): eventTrackingParams {
  const option = {
    business_type: 'wegic',
    theme: actionToTheme[action],
    action,
    distinct_id: nanoid(22),
    time: formatUTCTimestamp(),
    user_id: '',
    hrefUrl: window.location.href,
    uniqueid: getUniqueId(),
    sessionId: getSessionId(),
    count: Number.parseInt(sessionStorage.getItem(trackingCountKey) ?? '0'),
    properties: {
      user_agent: window.navigator.userAgent,
      device_type: browser.getPlatformType(),
      os: browser.getOSName(),
      screen_magnification: `${window.devicePixelRatio}`,
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      browser_window_size: `${window.innerWidth}x${window.innerHeight}`,
      userLanguage: window.navigator.language,
      referrer_url: document.referrer,
      isOnline,
      ...customProperties,
    },
  };

  return option;
}

export function dataWareHouse(action: keyof typeof actionToTheme, customProperties?: Record<string, unknown>) {
  trackEvent();
  const options = getOption(action, customProperties);
  return baseDataWareHouse(options).catch(() => {});
}
