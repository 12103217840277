import { dataWareHouse } from "@/helpers/dataWareHose"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default function RouterWrap({ children }: { children: React.ReactNode }) {
    const location = useLocation()

    useEffect(() => {
        dataWareHouse("page_view_wegicapp")
    }, [location])

    return children
}
