import queryString from "query-string";
import { MediaParams, MediaType } from "./const";
import { MediaFrame } from "@components/ui/MediaFrame";
import classNames from "classnames";
import styles from "./index.module.less";
import { useMemo } from "react";
import { FrameScreenShot } from "@components/FrameScreenShot";
interface EditableMediaProps extends React.HTMLAttributes<HTMLDivElement> {
  type: MediaType;
  params: string;
  propKey: string;
}

function getValidBool(value: unknown): boolean {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "string") {
    return value === "true";
  }
  return false;
}

export default function EditableMedia(props: EditableMediaProps) {
  const { type, params, propKey, className, ...nativeProps } = props;

  const jsonParams = useMemo(() => {
    return queryString.parse(params) as unknown as MediaParams["media"];
  }, [params]);

  const parsedParams = useMemo(() => {
    return {
      ...jsonParams,
      loop: getValidBool(jsonParams.loop),
      mute: getValidBool(jsonParams.mute),
      autoplay: getValidBool(jsonParams.autoplay),
    };
  }, [jsonParams]);

  if (type !== "media" && window.__isScreenShotMode__) {
    return <FrameScreenShot type={type} name={type} />;
  }

  return (
    <div
      data-element-type="media"
      className={classNames(
        styles["editable-media-frame"],
        styles[`editable-media-frame-${type}`],
        className,
        {
          [styles[
            `editable-media-frame-${
              (parsedParams as MediaParams["media"]).mimeType
            }`
          ]]: type === "media",
          "embed-element": type !== "media",
        }
      )}
      {...nativeProps}
    >
      <MediaFrame type={type} params={parsedParams} />
    </div>
  );
}
