import { memo, useState } from 'react';

import { getFormId, getJotformTitle } from '../handle';
import useIntercomEmbed from '../EmbedScript/useIntercomEmbed';
import { handleJotformScript } from '../EmbedScript/handle';
import EditableEmbed from '..';
import queryString from 'query-string';
import { useUpdateEffect } from 'ahooks';
import useTypeformPopoverStyleHacker from '../EmbedScript/useTypeformPopoverStyleHacker';

function EmbedPopover() {
  const [jotformTitle, setJotformTitle] = useState<string>('Feedback');
  const attachments = window.attachments;

  const popoverAttachment = attachments?.find(
    attachment => attachment.params.displayMode === 'popover' || attachment.type === 'intercom',
  );

  const type = popoverAttachment?.type;
  const url = popoverAttachment?.params.url;
  const intercomId = popoverAttachment?.params.id;
  const jotformId = getFormId(url);

  useUpdateEffect(() => {
    if (type !== 'jotform' || !jotformId) {
      return;
    }
    getJotformTitle(jotformId)
      .then(title => setJotformTitle(title))
      .catch(() => setJotformTitle('Feedback'));
  }, [type, jotformId]);

  useIntercomEmbed({ app_id: intercomId, type: type || '' });
  useTypeformPopoverStyleHacker({ enable: type === 'typeform' })

  if (!popoverAttachment || !type) {
    return null;
  }

  const renderAttachment = () => {
    if (type === 'jotform') {
      if (!jotformId) {
        return null;
      }
      handleJotformScript(jotformId, 'popover', jotformTitle);
      return null;
    }
    if (type === 'typeform') {
      return (
        <EditableEmbed
          type="typeform"
          params={queryString.stringify({
            url: popoverAttachment.params.url,
            displayMode: 'popover',
          })}
          propKey=''
        />
      );
    }
    return null;
  };

  return renderAttachment();
}

export default memo(EmbedPopover);
