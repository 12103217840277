import queryString from "query-string";

export interface IDataLink {
  link: string;
  target: HTMLAttributeAnchorTarget | undefined;
}

export interface ITextData extends IDataLink {
  text: string;
}

type HTMLAttributeAnchorTarget =
  | "_self"
  | "_blank"
  | "_parent"
  | "_top"
  | string;

export enum HTMLAttributeAnchorTargetEnum {
  /** 当前页面加载。（默认） */
  _self = "_self",
  /** 通常在新标签页打开，但用户可以通过配置选择在新窗口打开。 */
  _blank = "_blank",
  /** 当前浏览环境的父级浏览上下文。如果没有父级框架，行为与 _self 相同。 */
  _parent = "_parent",
  /** 最顶级的浏览上下文（当前浏览上下文中最“高”的祖先）。如果没有祖先，行为与 _self 相同。 */
  _top = "_top",
}

export function validateAnchorTarget(target: string): HTMLAttributeAnchorTarget {
  const validTargets: HTMLAttributeAnchorTarget[] = [
    "_self",
    "_blank",
    "_parent",
    "_top",
  ];

  // 检查 target 是否为一个有效的 HTMLAttributeAnchorTarget
  if (validTargets.includes(target)) {
    return target;
  }
  // 如果不是，则默认返回 '_blank'
  return "_blank";
}

export function handleTextProp(textProps: string | undefined | string[]): ITextData {
  try {
    let processedTextProps = textProps;
    if (processedTextProps === undefined) {
      return {
        text: "",
        link: "",
        target: HTMLAttributeAnchorTargetEnum._blank,
      };
    }

    if (Array.isArray(processedTextProps)) {
      const clearSpaceText = processedTextProps.find(text => text !== ' ');
      if (clearSpaceText) {
        processedTextProps = clearSpaceText;
      } else {
        throw new Error(`handleTextProp parse error, textProps: ${processedTextProps}`);
      }
    }


    // 兼容老数据，新数据都会经过 handleUserTextSave 处理，不会触发该 case
    if (!processedTextProps.includes("=")) {
      return {
        text: processedTextProps,
        link: "",
        target: HTMLAttributeAnchorTargetEnum._blank,
      };
    }

    const parsed = queryString.parse(processedTextProps);

    const link = typeof parsed.link === "string" ? parsed.link : "";

    const text = typeof parsed.text === "string" ? parsed.text : "";

    const target = validateAnchorTarget(
      typeof parsed.target === "string"
        ? parsed.target
        : HTMLAttributeAnchorTargetEnum._blank
    );

    return {
      link,
      target,
      text,
    };
  } catch (error) {
    return {
      text: "",
      link: "",
      target: HTMLAttributeAnchorTargetEnum._blank,
    };
  }
}

export function isEditorRoute(url: string) {
  const find = window.siteRoutes.find((route) => route?.path === url || (url?.startsWith('/') && `/${route?.path}` === url));
  return find ? {
        ...find,
        path: find.path.startsWith("/") ? find.path : `/${find.path}`,
      } : find;
}

export function changeTagTitle(title: string) {
  document.title = title;
}

export function getIsDefaultLink(link: string) {
  return link === '#' || link === '/';
}

