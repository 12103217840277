import { useRef } from 'react';

import type { EmbedScriptProps } from './const';
import { useJotformEmbed } from './useJotformEmbed';
import { useMailchimpEmbed } from './useMailchimpEmbed';
import { useTwitterEmbed } from './useTwitterEmbed';

function EmbedScript(props: EmbedScriptProps) {
  const { type, params } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useTwitterEmbed(type, params, containerRef);
  useJotformEmbed(type, params, containerRef);
  useMailchimpEmbed(type, params, containerRef);

  const render = () => {
    if (type === 'twitter' || (type === 'jotform' && params?.displayMode === 'popup') || type === 'mailchimp') {
      return <div data-embed-script={type} style={{ width: '100%', height: '100%' }} ref={containerRef}></div>;
    }
    return null;
  };

  return render();
}

export default EmbedScript;
