import { MediaType } from "@components/ui/MediaFrame/const";
import { EmbedType } from "@modules/BaseComponent/EditableEmbed/const";
import CustomEmbedIcon from "@assets/icons/brands/CustomEmbed.svg?react";
import FacebookIcon from "@assets/icons/brands/Facebook.svg?react";
import FigmaIcon from "@assets/icons/brands/Figma.svg?react";
import GoogleMapIcon from "@assets/icons/brands/GoogleMap.svg?react";
import ProductHuntIcon from "@assets/icons/brands/ProductHunt.svg?react";
import SoundCloudIcon from "@assets/icons/brands/SoundCloud.svg?react";
import SpotifyIcon from "@assets/icons/brands/Spotify.svg?react";
import VimeoIcon from "@assets/icons/brands/Vimeo.svg?react";
import XIcon from "@assets/icons/brands/X.svg?react";
import YoutubeIcon from "@assets/icons/brands/Youtube.svg?react";
import JotformIcon from "@assets/icons/brands/Jotform.svg?react";
import TypeformIcon from "@assets/icons/brands/Typeform.svg?react";
import GoogleFormsIcon from "@assets/icons/brands/GoogleForms.svg?react";
import IntercomIcon from "@assets/icons/brands/Intercom.svg?react";
import CalendlyIcon from "@assets/icons/brands/Calendly.svg?react";
import MailchimpIcon from "@assets/icons/brands/Mailchimp.svg?react";
import HubspotIcon from "@assets/icons/brands/Hubspot.svg?react";
export type ThirdPartyBrand = MediaType | EmbedType;

export function getThirdPartyBrandLogo(brand: ThirdPartyBrand) {
  switch (brand) {
    case "youtube":
      return YoutubeIcon;
    case "vimeo":
      return VimeoIcon;
    case "spotify":
      return SpotifyIcon;
    case "soundCloud":
      return SoundCloudIcon;
    case "customEmbed":
      return CustomEmbedIcon;
    case "twitter":
      return XIcon;
    case "facebook":
      return FacebookIcon;
    case "productHunt":
      return ProductHuntIcon;
    case "figma":
      return FigmaIcon;
    case "googleMap":
      return GoogleMapIcon;
    case 'jotform':
      return JotformIcon;
    case 'typeform':
      return TypeformIcon;
    case 'googleform':
      return GoogleFormsIcon;
    case 'intercom':
      return IntercomIcon;
    case 'calendly':
      return CalendlyIcon;
    case 'mailchimp':
      return MailchimpIcon;
    case 'hubspot':
      return HubspotIcon;
    default:
      return null;
  }
}
