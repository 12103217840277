
import { type ElementRect, type ImageExtra, ImageExtraKeys } from './const';


/** 判断参数是否能铺满图片 */
export function isSafeImageExtra(extra: ImageExtra, containerRect: ElementRect, imageRect: ElementRect) {
  const safeX = (imageRect.width * extra.scale - extra.x) >= containerRect.width;
  const safeY = (imageRect.height * extra.scale - extra.y) >= containerRect.height;
  return safeX && safeY;
}

/**
 * 获取当前满足条件的参数
 * @param extra 默认参数
 * @param containerRect 图片容器宽高
 * @param imageRect 图片本身宽高
 */
export function getSafeImageExtra(extra: ImageExtra, containerRect: ElementRect, imageRect: ElementRect): ImageExtra {
  const safeExtra: ImageExtra = { ...extra };
  const containerAspectRatio = containerRect.width / containerRect.height;
  const imageAspectRatio = imageRect.width / imageRect.height;
  const minScale = Math.max(containerRect.width / imageRect.width, containerRect.height / imageRect.height, 1);

  if ((safeExtra.scale > 1)) {
    // 已有参数不满足时尝试纠正，计算模拟cover

    const safeX = (imageRect.width * minScale - safeExtra.x) >= containerRect.width;
    const safeY = (imageRect.height * minScale - safeExtra.y) >= containerRect.height;
    if (!safeX) {
      safeExtra.scale = minScale;
      safeExtra.x = imageRect.width * safeExtra.scale - containerRect.width;
    }
    if (!safeY) {
      safeExtra.scale = minScale;
      safeExtra.y = imageRect.height * safeExtra.scale - containerRect.height;
    }
  } else {
    // 完全重置，根据图片计算一个选区
    if (imageAspectRatio < containerAspectRatio) {
    // 宽拉满，垂直居中
      safeExtra.x = 0;
      safeExtra.y = (imageRect.height * minScale / 2) - (containerRect.height / 2);
    } else {
    // 高拉满，水平居中
      safeExtra.y = 0;
      safeExtra.x = (imageRect.width * minScale / 2) - (containerRect.width / 2);
    }
    safeExtra.scale = minScale;
  }

  return safeExtra;
}

export function getInitialSelection(containerRect: ElementRect, cropperRect: ElementRect) {
  const targetAspectRatio = containerRect.width / containerRect.height;
  const selection = { width: 0, height: 0, x: 0, y: 0 };
  const offset = 1; // 比极限值减少一点，避免选区因为小数问题影响
  const imageAspectRatio = cropperRect.width / cropperRect.height;
  if (imageAspectRatio < targetAspectRatio) {
    selection.width = cropperRect.width - offset;
    selection.height = selection.width / targetAspectRatio;
    selection.y = cropperRect.height / 2 - selection.height / 2;
    selection.x = offset;
  } else {
    selection.height = cropperRect.height - offset;
    selection.width = selection.height * targetAspectRatio;
    selection.x = cropperRect.width / 2 - selection.width / 2;
    selection.y = offset;
  }
  return selection;
}

export function getURLWithoutExtra(urlStr?: string) {
  if (!urlStr) {
    return '';
  }
  const url = new URL(urlStr);
  const { searchParams } = url;
  ImageExtraKeys.forEach(key => searchParams.delete(key));
  return url.toString();
}
