import { useEffect } from 'react';

import type { EmbedScriptProps } from './const';
import { handleMailchimpScript } from './handle';
import { EmbedParams, EmbedType } from '../const';

export function useMailchimpEmbed(type: EmbedType, params: EmbedScriptProps['params'], containerRef: React.RefObject<HTMLDivElement>) {
  const { content } = params as unknown as EmbedParams['mailchimp'];

  useEffect(() => {
    if (type !== 'mailchimp' || !containerRef.current) {
      return;
    }
    handleMailchimpScript();
    containerRef.current.innerHTML = content;
  }, [type, content]);
}
